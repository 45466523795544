import React from "react"
import { renderHtmlToReact } from "../utils/html"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const ContactsFooter = ({sidebar}) => {
  const data = useStaticQuery(graphql`
    query {
      contactsJson {
        phone
        mail {
          childMarkdownRemark {
            htmlAst
          }
        }
        address {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
  `)

  return (
    <ContactsFooterWrapper sidebar={sidebar}>
      <h2>{data.contactsJson.phone}</h2>
      <h3><section>{renderHtmlToReact(data.contactsJson.mail.childMarkdownRemark.htmlAst)}</section></h3>
      <div className="contacts-content-text">
        <section>{renderHtmlToReact(data.contactsJson.address.childMarkdownRemark.htmlAst)}</section>
      </div>
    </ContactsFooterWrapper>
    )
}

const ContactsFooterWrapper = styled.section`

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: ${props => props.sidebar ? "140px" : "0"};

  * {
    color: ${props => props.sidebar ? "black" : "white"};
    font-family: 'Exo 2', sans-serif;
    font-weight: 200;
  }

  h2 {
    font-size: ${props => props.sidebar ? "32px" : "50px"};
    margin-bottom: 10px;

    @media screen and (max-width: 900px) {
      font-size: 32px;
    }
  }

  h3 {
    font-size: 24px;
    margin-bottom: ${props => props.sidebar ? "0" : "16px"};

    @media screen and (max-width: 900px) {
      margin-bottom: 0;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .contacts-content-text {
    line-height: ${props => props.sidebar ? "17px" : "18px"};
    @media screen and (max-width: 900px) {
      line-height: 17px;
    }

    p {
        font-size: ${props => props.sidebar ? "14px" : "15px"};
        font-weight: ${props => props.sidebar ? "300" : "200"};
        opacity: ${props => props.sidebar ? "1" : "0.5"};
        margin-bottom: 10px;

        @media screen and (max-width: 900px) {
          font-size: 14px;
        }
    }
  }
`

export default ContactsFooter
