import React from "react"
import styled from "styled-components"
import NavLinks from './navLinks'
import ContactsFooter from '../Contacts/contactsFooter'
import BarButton from '../BarButton/barButton'

const Sidebar = ({sidebar, toggle}) => {
  return (
    <Wrapper active={sidebar} >
      <Overlay />
      <SliderWrapper>
        <BarButton toggle={toggle}  sidebar />
        <div className="nav-links">
          <NavLinks keyAdd='navbar' toggle={toggle} />
        </div>
        <SeparateBar />
        <ContactsFooter sidebar />
      </SliderWrapper>
      </Wrapper>
  )}


const SeparateBar = styled.div`
  width: 264px;
  height: 1px;
	background-color: black;
  opacity: 0.2;
  transform: scaleY(-1);
  margin: 0 auto 24px auto;
`

const Wrapper = styled.div`
  width: 100%;
  display: ${(props) => (props.active ? "block" : "none")};
`

const SliderWrapper = styled.nav`
  width: 360px;
  height: 100vh;
  background: white;
  position: fixed;
  right: 0px;
  z-index: 2002;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 360px) {
    width: 100vw;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    ul {
      list-style: none;
      text-align: center;
      margin: 0 auto;

      li {
        font-family: 'Exo 2', sans-serif;
        font-size: 24px;
        font-weight: 300;
        line-height: 1.5;
        color: #26B7FA;
        text-transform: uppercase;
        a {
          cursor: pointer;
        }
      }
    }
  }
`

const Overlay = styled.div`
  position: fixed;
  background: #F4F4F7;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  display: none;
  transition: 0.4s;
  display: block;
  z-index: 2001;
`

export default Sidebar
