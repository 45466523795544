import React from "react"
import { renderHtmlToReact } from "../utils/html"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const Career = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "career.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobileImage: file(relativePath: { eq: "career-small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopMediumImage: file(relativePath: { eq: "career-medium.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2373, quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "career.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      careerJson {
        title
        content {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
  `)

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopMediumImage.childImageSharp.fluid,
      media: `(min-width: 901px)`,
    },
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 1101px)`,
    },
  ]

  return (
    <CareerWrapper id="career">
      <div className="career-background-top">
      </div>
      <BackgroundImage
        className="career-image"
        fluid={sources}
      >
        <div className="career-content">
          <div className="career-content-text">
            <h1>{data.careerJson.title}</h1>
            <section>{renderHtmlToReact(data.careerJson.content.childMarkdownRemark.htmlAst)}</section>
          </div>
        </div>
      </BackgroundImage>
    </CareerWrapper>
    )
}

const CareerWrapper = styled.section`

  @media screen and (max-width: 900px) {
    background: linear-gradient(#3F626F 0%, #3F626F 25%, rgba(255,0,0,0) 40%, rgba(255,0,0,0) 100%);
  }
  .career-background-top {
    height: 64px;
    background: #3F626F;
    display: none;

    @media screen and (max-width: 900px) {
      display: block;
    }

  }

  .career-content {
    height: 100%;
    text-align: left;
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media screen and (max-width: 900px) {
      justify-content: center;
      background: linear-gradient(#3F626F 0%, #3F626F 7%, rgba(255,0,0,0) 50%, rgba(255,0,0,0) 100%);
    }

    h1 {
      font-family: 'Exo 2', sans-serif;
      font-size: 60px;
      font-weight: 200;
      color: #FFFFFF;
      text-align: left;

      @media screen and (max-width: 900px) {
        font-size: 32px;
        text-align: center;
      }
    }

    p {
      font-family: 'Exo 2', sans-serif;
      font-size: 19px;
      font-weight: 300;
      line-height: 23px;
      color: #FFFFFF;

      @media screen and (max-width: 900px) {
        font-size: 16px;
        line-height: 19px;
      }

      a {
        color: inherit;
      }
    }
  }

  .career-image {
    height: 466px;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 900px) {
      height: 379px;
    }
  }

  .career-content-text {
    padding-top: 75px;
    width: 435px;
    position: absolute;
    text-align: left;

    @media screen and (max-width: 900px) {
      width: 328px;
      text-align: center;
      padding-top: 20px;
      margin-top: -70px;
    }
  }

`

export default Career
