import React from "react"
import { renderHtmlToReact } from "../utils/html"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const Technologies = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "technology-small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080, quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "technology.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2121, quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      technologiesJson {
        title
        content {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
  `)

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 901px)`,
    },
  ]

  const getTechnologiesContentDescription = () =>
    <React.Fragment>
      <div className="technologies-content-text">
        <h1>{data.technologiesJson.title}</h1>
        <section>{renderHtmlToReact(data.technologiesJson.content.childMarkdownRemark.htmlAst)}</section>
      </div>
    </React.Fragment>

  return (
    <TechnologiesWrapper id="technologies">
      <BackgroundImage
        className="technologies-image"
        fluid={sources}
      >
        <div className="technologies-gradient">
          <div className="technologies-content technologies-content-common">
            {getTechnologiesContentDescription()}
          </div>
        </div>
      </BackgroundImage>
      <div className="technologies-content-mobile technologies-content-common">
        {getTechnologiesContentDescription()}
      </div>
    </TechnologiesWrapper>
    )
}

const TechnologiesWrapper = styled.section`

  .technologies-image {
    height: 830px;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 1100px) {
      height: 670px;
    }

    @media screen and (max-width: 900px) {
      height: 338px;
    }
  }

  .technologies-gradient {
    height: 100%;
    @media screen and (max-width: 900px) {
      background: linear-gradient(rgba(238,245,247,0) 0%, rgba(238,245,247,0) 75%, #D8E7EB 100%);
    }
  }

  .technologies-content {
    text-align: left;
    max-width: 1100px;
    align-items: flex-end;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  .technologies-content-mobile {
    display: none;

    @media screen and (max-width: 900px) {
      display: flex;
      justify-content: center;
      background-color: #D7E7EB;
    }
  }

  .technologies-content-common {
    height: 100%;
    margin: 0 auto;
    padding: 20px 20px;

    h1 {
      font-family: 'Exo 2', sans-serif;
      font-size: 60px;
      font-weight: 200;
      color: rgba(0,0,0,0.54);
      text-align: left;

      @media screen and (max-width: 900px) {
        font-size: 32px;
        text-align: center;
      }
    }

    p {
      font-family: 'Exo 2', sans-serif;
      font-size: 19px;
      font-weight: 300;
      line-height: 23px;
      color: rgba(0,0,0,0.87);

      @media screen and (max-width: 1100px) {
        margin-bottom: 1rem;
      }

      @media screen and (max-width: 900px) {
        font-size: 16px;
        line-height: 19px;
      }

      a {
        color: inherit;
      }
    }
  }

  .technologies-content-text {
    width: 463px;
  }
`

export default Technologies
