import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import ServiceItem from "./serviceItem"
import Slider from "react-slick"

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      servicesJson {
        title
        gallery {
          title
          content {
            childMarkdownRemark {
              htmlAst
              rawMarkdownBody
            }
          }
          image {
            childImageSharp {
              fluid(maxHeight: 200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  }

  return (
    <ServicesWrapper id="services">
      <h1>{data.servicesJson.title}</h1>
      <div className="first-row-services row-services">
        {data.servicesJson.gallery.slice(0, 3).map((item, index) =>
          <ServiceItem
            key={`service-${index}-1`}
            title={item.title}
            content={item.content.childMarkdownRemark.rawMarkdownBody}
            img={item.image}
          />
        )}
      </div>
      <div className="second-row-services row-services">
        {data.servicesJson.gallery.slice(3).map((item, index) =>
          <ServiceItem
            key={`service-${index}-2`}
            title={item.title}
            content={item.content.childMarkdownRemark.rawMarkdownBody}
            img={item.image}
          />
        )}
      </div>
      <Slider {...settings}>
        {data.servicesJson.gallery.map((item, index) =>
            <ServiceItem
              key={`service-${index}-1`}
              title={item.title}
              content={item.content.childMarkdownRemark.rawMarkdownBody}
              img={item.image}
            />
          )}
      </Slider>
    </ServicesWrapper>
    )
}

const ServicesWrapper = styled.section`
  margin: 120px auto 0;
  display: flex;
  flex-direction: column;
  max-width: 900px;

  @media screen and (max-width: 900px) {
    margin-top: 64px;
  }

  h1 {
    font-family: 'Exo 2', sans-serif;
    font-size: 60px;
    font-weight: 200;
    color: rgba(0,0,0,0.54);
    text-align: center;
    margin-bottom: 65px;

    @media screen and (max-width: 900px) {
      font-size: 32px;
      padding: 0 16px;
      margin-bottom: 32px;
    }
  }

  .row-services {
    margin: 0 auto 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  .second-row-services {
    padding-left: calc(100%/6);
    padding-right: calc(100%/6);
  }

  .slick-slider {
    overflow: hidden;
    position:relative;
    width: auto;
    display: none;
    margin-bottom: 64px;

    @media screen and (max-width: 900px) {
      display: block;
    }
  }

  .slick-slider .slick-list div {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .slick-dots {
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 3px;
    border: 0;
    outline: none;
    width: 50%;
    height: 50%;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #26B7FA;
    background: transparent;
  }

  .slick-dots li {
    display: inline-block;
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  .slick-dots li.slick-active button {
    background: #26B7FA;
  }
`

export default Services
