import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "top-background-small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "top-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bannerJson {
        title
        subtitle
        content {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
    }
  `)

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 901px)`,
    },
  ]

  const getBannerContentDescription = () =>
    <React.Fragment>
      <h2>
        {data.bannerJson.subtitle}
      </h2>
      <p>
        {data.bannerJson.content.childMarkdownRemark.rawMarkdownBody}
      </p>
    </React.Fragment>

  return (
    <BannerWrapper id="banner">
      <BackgroundImage
        className="banner-image"
        fluid={sources}
      >
        <div className="banner-gradient">
          <div className="banner-content">
            <h1>
              {data.bannerJson.title}
            </h1>
            <div className="banner-content-description-common banner-content-description">
              {getBannerContentDescription()}
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="banner-content-description-common banner-content-description-mobile">
        {getBannerContentDescription()}
      </div>
    </BannerWrapper>
    )
}

const BannerWrapper = styled.section`
  .banner-gradient {
    background: linear-gradient(transparent 0%, transparent 75%, white 100%);
    height: 100%;
  }

  * {
    font-family: 'Exo 2', sans-serif;
  }

  h1 {
    font-size: 90px;
    font-weight: 200;
    margin-bottom: 18px;
    min-width: 260px;


    @media screen and (max-width: 900px) {
      font-size: 48px;
    }
  }

  h2 {
    font-size: 60px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 18px;
    margin-left: 20px;
    margin-right: 20px;

    @media screen and (max-width: 900px) {
      font-size: 32px;
    }
  }

  p {
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    font-weight: 300;
    min-width: 310px;
    max-width: 463px;
    line-height: 1.5;
    font-style: italic;
    margin: 0 20px;

    @media screen and (max-width: 900px) {
      font-size: 20px;
    }
  }

  .banner-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 130px;
    @media screen and (max-width: 900px) {
      padding-top: 385px;
    }
  }

  .banner-content-description-common {
    text-align: center;
    align-items: center;
  }

  .banner-content-description {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  .banner-content-description-mobile {
    display: none;

    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
    }
  }

  .banner-image,
  .banner-image::before,
  .banner-image::after {
    min-height: 850px;
    height: 100vh;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 900px) {
      height: 445px;
      min-height: 445px;
    }
  }

`

export default Banner
