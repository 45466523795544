import React from "react"
import styled from "styled-components"
import LeafletMap from './leafletMap'
import Logo from "../../images/softlite(white)-logo.svg"
import NavLinks from '../Navbar/navLinks'
import ContactsFooter from './contactsFooter'

const Contacts = () => (
  <ContactsWrapper id="contacts">

    {typeof window !== 'undefined' &&
      <LeafletMap
        position={[55.205928, 61.279583]}
        zoom={17}
        markerText={"Мы здесь"}
        mapType="YandexMap"
      />
    }

    <div className="contacts-content-box">
      <img src={Logo} alt="Startup Logo" />
      <div className="contacts-links">
        <NavLinks keyAdd='contacts' />
      </div>
      <ContactsFooter />
    </div>
  </ContactsWrapper>
)

const ContactsWrapper = styled.section`

  .contacts-links {
    margin: 0 auto;

    ul {
      display: flex;
      list-style: none;
      margin: 0 auto;
      margin-bottom: 36px;

      @media screen and (max-width: 900px) {
        display: block;
      }

      li {
        font-family: 'Exo 2', sans-serif;
        font-size: 13px;
        margin-left: 1rem;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 0;
        a {
          cursor: pointer;
        }

        @media screen and (max-width: 900px) {
          font-size: 16px;
          margin-left: 0;
        }
      }
    }
  }

  .contacts-content-box {
    height: 400px;
    background-color: #181C1F;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: 53px;
    padding-bottom: 53px;

    @media screen and (max-width: 900px) {
      height: 500px;
    }

    img {
      margin-bottom: 16px;

      @media screen and (max-width: 900px) {
        margin-bottom: 32px;
      }
    }
  }
`

export default Contacts
