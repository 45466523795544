import React from "react"
import { renderHtmlToReact } from "../utils/html"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "globe.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aboutJson {
        title
        content {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
  `)

  return (
    <AboutWrapper id="about">
      <div className="about-content">
        <div className="about-content-text">
          <h1>{data.aboutJson.title}</h1>
          <section>{renderHtmlToReact(data.aboutJson.content.childMarkdownRemark.htmlAst)}</section>
        </div>
      </div>
      <ImageWrapper>
        <Img className="about-image" fluid={data.image.childImageSharp.fluid} />
        <br />
      </ImageWrapper>
    </AboutWrapper>
  )
}

const AboutWrapper = styled.section`
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    padding-bottom: 10px;
  }

  .about-image {
    width: 900px;
    overflow: visible;
    z-index: -5;
    margin-left: -48px;

    @media screen and (max-width: 900px) {
      overflow: hidden;
        width: 630px;
    }
  }

  .about-content-text {
    padding-top: 75px;
    width: 483px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 900px) {
      max-width: 463px;
      width: 100%;
      padding-top: 0;
      margin-top: -40px;
      align-items: center;
    }
  }

  .about-content {
    display: flex;
    flex-direction: row;
    height: 100%;
    text-align: center;
    justify-content: center;
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px 20px;

    @media screen and (max-width: 900px) {
      width: 100%;
    }

    * {
      font-family: 'Exo 2', sans-serif;
    }

    h1 {
      font-size: 60px;
      font-weight: 200;
      color: rgba(0,0,0,0.54);
      text-align: left;
      width: 350px;

      @media screen and (max-width: 900px) {
        font-size: 32px;
      }
    }

    p {
      font-size: 19px;
      font-weight: 300;
      color: rgba(0,0,0,0.87);
      text-align: left;

      @media screen and (max-width: 900px) {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 1rem;
      }
    }
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  overflow: hidden;
`

export default About
