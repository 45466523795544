import React from "react"
import {Link} from "react-scroll"

const NAV_ITEMS = [
  {
    section: "about",
    text: "О нас",
  },
  {
    section: "technologies",
    text: "Технологии",
  },
  {
    section: "services",
    text: "Услуги",
  },
  {
    section: "career",
    text: "Карьера",
  },
  {
    section: "contacts",
    text: "Контакты",
  },
]

const NavLinks = ({toggle, keyAdd}) => {
    return (
      <ul>
        {NAV_ITEMS.map((item, index) => (
            <li key={`${keyAdd}-${index}`}>
              <Link
                activeClass="active"
                to={item.section}
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
                onClick={toggle}
              >
                {item.text}
              </Link>
            </li>)
          )}
      </ul>
    )
}

export default NavLinks
