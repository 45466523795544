import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const ServiceItem = ({title, content, img}) => {
  return (
    <ServiceItemWrapper id="services">
      <Img className="service-image" fluid={img ? img.childImageSharp.fluid : {}} alt={title} />
      <h3>{title}</h3>
      <p>{content}</p>
    </ServiceItemWrapper>
    )
}

const ServiceItemWrapper = styled.section`
  width: 280px;
  margin-left: 8px;
  margin-right: 8px;

  h3 {
    font-family: 'Exo 2', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 19px;
    color: #26B7FA;
    margin-bottom: 10px;
  }

  p {
    color: #000000;
    font-family: "Exo 2";
    font-size: 14px;
    font-weight: 200;
    line-height: 17px;
    margin: 0 auto;
  }

  img {
    margin-bottom: 25px;
  }

  .service-image {
    margin-bottom: 25px;
  }
`

export default ServiceItem
