import React, { Component } from "react"
import styled from "styled-components"
import Logo from "../../images/softlite-logo.svg"
import {Link} from "react-scroll"
import NavLinks from './navLinks'
import BarButton from '../BarButton/barButton'
import Sidebar from "./sidebar"

export default class Navbar extends Component {
  state = {
    hasScrolled: false,
    sidebar: false,
  }

  constructor(props) {
    super(props);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleSidebar = function() {
    this.setState({sidebar: !this.state.sidebar})
  }

  render() {
    return (
      <React.Fragment>
        <NavWrapper {...this.props} scrolled={this.state.hasScrolled}>
          <div className="masthead">
            <Link
              activeClass="active"
              to="banner"
              spy={true}
              smooth={true}
              duration={500}
            >
              <img src={Logo} alt="Startup Logo" />
            </Link>
          </div>
          <div className="nav-links">
            <NavLinks keyAdd='navbar' />
          </div>
          <BarButton toggle={this.toggleSidebar}/>
        </NavWrapper>
        <Sidebar sidebar={this.state.sidebar}  toggle={this.toggleSidebar} />
      </React.Fragment>
    )
  }
}

const NavWrapper = styled.nav`
  position: fixed;
  z-index: 2000;
  width: 100%;
  height: 70px;
  padding: 23px 128px;
  display: flex;
  justify-content: space-between;
  background: ${props => (props.scrolled ? `rgba(255, 255, 255, 0.5)` : null)};
  @media screen and (max-width: 900px) {
    background: rgba(255, 255, 255, 0.5);
    padding: 16px 16px;
    height: 56px;
  }

  img {
    width: 210px;
    @media screen and (max-width: 900px) {
      width: 160px;
      min-width: 160px;
    }
  }

  .nav-links {
    display: block;
    @media screen and (max-width: 900px) {
      display: none;
    }

    ul {
      display: flex;
      list-style: none;

      li {
        font-family: 'Exo 2', sans-serif;
        font-size: 13px;
        margin-left: 1rem;
        text-transform: uppercase;
        a {
          cursor: pointer;
        }

      }
    }
  }

  .masthead {
    cursor: pointer;
  }
`
