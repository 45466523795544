import React from "react"

import Layout from "../components/layout"
import Banner from "../components/Banner/banner"
import SEO from "../components/Seo/seo"
import Navbar from "../components/Navbar/navbar"
import About from "../components/About/about"
import Technologies from "../components/Technologies/technologies"
import Services from "../components/Services/services"
import Career from "../components/Career/career"
import Contacts from "../components/Contacts/contacts"

export default () => (
  <Layout>
    <SEO  title="Софт Лайт" />
    <Navbar />
    <Banner />
    <About />
    <Technologies />
    <Services />
    <Career />
    <Contacts />
  </Layout>
)
