import React, {Component} from 'react'
import {Map, TileLayer, Marker, Popup, ZoomControl} from 'react-leaflet'
import {YMaps, Map as YaMap, Placemark, ZoomControl as YaZoomControl} from "react-yandex-maps"
import styled from "styled-components"

class LeafletMap extends Component {

  static defaultProps = {
    markerText: "",
    scrollWheelZoom: false,
    zoomControl: false,
  }

  render() {
    const mapData = {
      center: this.props.position,
      zoom: this.props.zoom,
      behaviors: ["disable('scrollZoom')", "drag"]
    }

    const placeMark = {
        geometry: this.props.position,
        properties: {
            balloonContent: 'Россия, Челябинск, Габдуллы Тукая 20а, пом. 2'
        },
        modules: ['geoObject.addon.balloon']}

    return (
      <MapWrapper>
        {this.props.mapType === 'YandexMap'
        ? <div className='yamap-container'>
          <YMaps
            query={{apikey: '7afbcd61-8a2f-49b2-ac53-003de5f0ccf1'}}
          >
            <YaMap
              defaultState={mapData}
              height={'100%'}
              width={'100%'}
            >
              <Placemark {...placeMark} />
              <YaZoomControl />
            </YaMap>
          </YMaps>
        </div>
        : <Map center={this.props.position} zoom={this.props.zoom} scrollWheelZoom={this.props.scrollWheelZoom} zoomControl={this.props.zoomControl}>
          <TileLayer
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          />
          <ZoomControl position="bottomright" />
          {this.props.markerText !== "" &&
          <Marker position={this.props.position}>
            <Popup>{this.props.markerText}</Popup>
          </Marker>
          }
        </Map>
        }
      </MapWrapper>
    )
  }
}

const MapWrapper = styled.section`
  .leaflet-container {
    height: 500px;
    width: 100%;
    filter: hue-rotate(200deg) grayscale(50%) invert(100%);
  }

  .yamap-container {
    height: 500px;

    @media screen and (max-width: 900px) {
      height: 460px;
    }
  }
`

export default LeafletMap
