import React from "react"
import styled from "styled-components"

const BarButton = ({toggle, sidebar}) => {

  return <BarButtonWrapper sidebar={sidebar} onClick={() => toggle()}>
      <Bar top sidebar={sidebar}/>
      <Bar middle sidebar={sidebar}/>
      <Bar bottom sidebar={sidebar}/>
    </BarButtonWrapper>
}

const BarButtonWrapper = styled.div`
  display: none;
  cursor: pointer;
  margin-top: 4px;

  @media screen and (max-width: 900px) {
    display: block;
  }

  ${({ sidebar }) =>
    sidebar &&
    `
    display: block;
    position: fixed;
    right: 18px;
    top: 18px;
    `}
`

const Bar = styled.div`
  width: 22px;
  height: 2px;
  margin-bottom: 4px;
	background-color: #26B7FA;

	${({ middle, sidebar }) =>
    middle &&
    sidebar &&
		`
		transform: scale(0);
		`}

	${({ top, sidebar }) =>
    top &&
    sidebar &&
		`
		transform: translateY(6px) rotate(45deg);
		`}

	${({ bottom, sidebar }) =>
    bottom &&
    sidebar &&
		`
		transform: translateY(-6px) rotate(-45deg);
		`}

  `

export default BarButton
